import { createApp, nextTick } from 'vue';
import SearchBar from './_component.vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const searchChurchesSearch = {
  cfg: {
    name: 'searchChurchesSearch',
    selectors: {
      search: 'o-searchChurchesSearch',
      modal: '#searchModal',
      button: '.searchButton',
      input: '#searchbarInput',
    },
    el: {
      $search: undefined,
      $modal: undefined,
      $buttons: [],
      $input: undefined,
    },
  },

  setElements() {
    this.cfg.el.$search = document.querySelector(this.cfg.selectors.search);
    this.cfg.el.$modal = document.querySelector(this.cfg.selectors.modal);
    this.cfg.el.$buttons = document.querySelectorAll(this.cfg.selectors.button);
  },

  checkUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('gottesdienstsuche')) {
      this.openModal();
    }
  },

  // Set focus on input field when modal is opened
  async setFocus() {
    await nextTick();
    this.cfg.el.$input = document.querySelector(this.cfg.selectors.input);
    if (this.cfg.el.$input) {
      this.cfg.el.$input.focus();
    }
  },

  init() {
    this.setElements();
    this.initVue();

    this.cfg.el.$buttons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        this.openModal();
      });
    });

    this.checkUrl();
  },

  openModal() {
    this.cfg.el.$modal.showModal();
    this.toggleScrolling(true);
    this.setFocus();

    // Update URL
    const url = new URL(window.location.href);
    if (!url.searchParams.has('gottesdienstsuche')) {
      url.searchParams.set('gottesdienstsuche', '');
      window.history.pushState({}, '', url.toString());
    }
  },

  toggleScrolling(isStopped) {
    const targetElement = this.cfg.el.$modal;
    if (!targetElement) return;

    if (isStopped) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  },

  getRecentSearch() {
    const cookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('recentChurches='))
      ?.split('=')[1];
    return cookie ? JSON.parse(decodeURIComponent(cookie)) : [];
  },

  initVue() {
    if (!this.cfg.el.$modal.dataset.vueMounted) {
      this.cfg.el.$modal.dataset.vueMounted = 'true';

      const vueSearchElem = document.querySelector('#vueSearchChurches');

      if (vueSearchElem) {
        const featuredChurches = vueSearchElem.dataset.featured
          ? vueSearchElem.dataset.featured.split(',').map((id) => parseInt(id))
          : undefined;

        const lastSearch = this.getRecentSearch();
        console.log('Last Search: ', lastSearch);

        const app = createApp(SearchBar, {
          featured: featuredChurches,
          lastSearch: lastSearch,
          onMounted: () => {
            this.setFocus();
          },
        });

        app.mount('#vueSearchChurches');
      }
    }
  },
};

export default searchChurchesSearch;
