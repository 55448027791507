/*
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * churchServicesSidebar
 */

const churchServicesSidebar = {
  openDatepicker(event) {
    event.preventDefault();
    event.target.showPicker();
  },

  formatDate(date) {
    // If date is already in yyyy-mm-dd, just return it
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }

    // If date is in dd.mm.yyyy format, convert it
    if (/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
      const parts = date.split(".");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    // If invalid, return empty string
    return "";
  },


  updateDateInputsFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const startDate = urlParams.get("startDate");
    const endDate = urlParams.get("endDate");

    if (startDate) {
      const formattedStartDate = this.formatDate(startDate);
      const startDateInput = document.getElementById("startDate");
      if (startDateInput) startDateInput.value = formattedStartDate;
    }

    if (endDate) {
      const formattedEndDate = this.formatDate(endDate);
      const endDateInput = document.getElementById("endDate");
      if (endDateInput) endDateInput.value = formattedEndDate;
    }
  },

  initDatepicker() {
    document.getElementById("startDate")?.addEventListener("click", this.openDatepicker);
    document.getElementById("endDate")?.addEventListener("click", this.openDatepicker);
  },

  initMobileFilterAccordion() {
    const trigger = document.querySelector(".o-churchServicesSidebar__filterAccordionMobileTrigger");
    const sidebar = document.querySelector(".e-twoColumnsSticky__sidebar");

    if (trigger && sidebar) {
      trigger.addEventListener("click", () => {
        trigger.classList.toggle("is-open");
        sidebar.classList.toggle("is-open");
      });
    }
  },

  init() {
    this.initMobileFilterAccordion();
    this.initDatepicker();
    this.updateDateInputsFromUrl();
  },
};
export default churchServicesSidebar;
