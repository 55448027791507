<!-- eslint-disable -->
<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 13.3998L20.5999 7.7998L22.1999 9.3998L16.6 14.9998L22.2 20.5998L20.6 22.1998L15 16.5998L9.39993 22.1998L7.79993 20.5998L13.4 14.9998L7.80002 9.3998L9.40002 7.7998L15 13.3998Z" fill="currentColor"/>
  </svg>


</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'CloseIcon',
};
</script>
