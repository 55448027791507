<script setup>
import axios from 'axios';
import { reactive, defineProps, computed, onMounted } from 'vue';
import SearchBar from './__Searchbar.vue';
import Logo from './__logo.vue';
import MetaNav from './__metaNav.vue';

const churches = reactive({
  list: [],
  selectedId: undefined,
  selectedItem: undefined,
});

const churchList = reactive({
  list: [],
});

const props = defineProps({
  featured: Array,
  lastSearch: Array,
});

onMounted(() => {
  if (props.onMounted && typeof props.onMounted === 'function') {
    props.onMounted();
  }
});

// Determine search source (either "lastSearch" or "featured")
const isFeaturedSearch = computed(() => props.lastSearch.length === 0);

const urls = ['/intentio/active-churches', '/intentio/inactive-churches'];
const requests = urls.map((url) => axios.get(url));

axios
  .all(requests)
  .then((responses) => {
    responses.forEach(({ data }) => {
      churches.list.push(...data);
    });

    if (props.lastSearch.length > 0) {
      // Preserve the order from `props.lastSearch`
      churchList.list = props.lastSearch
        .map((id) => churches.list.find((church) => church.churchId === id))
        .filter(Boolean); // Remove undefined entries if any ID is not found
    } else {
      const featuredChurchIds = new Set(props.featured?.map((id) => parseInt(id)) || []);
      churchList.list = churches.list.filter((church) => featuredChurchIds.has(church.churchId));
    }
  })
  .catch((e) => console.error(e));
</script>

<template>
  <div v-show="churches.list.length > 0">
    <div class="flex items-start">
      <div class="hidden h-auto min-w-[17.5%] flex-shrink-0 md:flex">
        <a href="/" aria-label="Zur Startseite">
          <Logo />
        </a>
      </div>

      <div class="flex w-full flex-col items-baseline gap-[55px] pt-4 md:pt-0">
        <div class="hidden md:block">
          <MetaNav></MetaNav>
        </div>
        <div class="w-full">
          <SearchBar :all-churches="churches" :churchList="churchList.list" :isFeaturedSearch="isFeaturedSearch" />
        </div>
      </div>
    </div>
  </div>
</template>
