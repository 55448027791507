<template>
  <!-- Show loading message if loading -->
  <div v-if="isLoading">Lade Gottesdienste {{ loadingDots }}</div>

  <div
    v-else-if="filteredChurches.length > 0"
    class="order-1 w-full md:order-none md:max-w-[715px]"
  >
    <!-- Dynamic Search Header (Hidden while loading) -->
    <div class="w-full font-[demos-next-condensed] font-light text-sm md:mb-4 tracking-normal">
      <div v-if="filteredChurches.length <= 20">
        <template v-if="!searchTerm && !fromDate && !toDate">
          <span v-if="isFeaturedSearch">Häufig gesucht:</span>
          <span v-else>Zuletzt angesehen:</span>
        </template>


        <template v-if="searchTerm && !fromDate && !toDate">
          Gottesdienste für: <span class="text-turquoise-400">"{{ searchTerm }}"</span>
        </template>

        <template v-else-if="!searchTerm && (fromDate || toDate)">
          In diesen Kirchen gibt es Gottesdienste
          <template v-if="fromDate && toDate"
            >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
          >
          <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
          <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template
          >.
        </template>

        <template v-else-if="searchTerm && (fromDate || toDate)">
          In diesen Kirchen gibt es Gottesdienste
          <template v-if="fromDate && toDate"
            >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
          >
          <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
          <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template
          >.
        </template>
      </div>

      <!-- 20+ Results -->
      <div v-else>
        <template v-if="searchTerm && !fromDate && !toDate">
          Zahlreiche Treffer für: <span class="text-turquoise-400">"{{ searchTerm }}"</span>. Tipp:
          Grenzen Sie Ihre Treffer für Gottesdienste zeitlich ein.
        </template>

        <template v-else-if="!searchTerm && (fromDate || toDate)">
          In all diesen Kirchen gibt es Gottesdienste
          <template v-if="fromDate && toDate"
            >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
          >
          <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
          <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template
          >. Tipp: Grenzen Sie Ihre Treffer mittels Orts- oder Kircheneingabe ein.
        </template>

        <template v-else-if="searchTerm && (fromDate || toDate)">
          In all diesen Kirchen gibt es Gottesdienste
          <template v-if="fromDate && toDate"
            >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
          >
          <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
          <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template
          >.
        </template>
      </div>
    </div>

    <!-- Church List -->
    <a
      v-for="church in filteredChurches"
      :key="church.churchId"
      :href="`gottesdienste?church=${church.churchId}`"
      class="group my-2 flex items-center justify-start gap-4 bg-white outline-none transition-colors duration-150 md:hover:bg-gray-50 focus:bg-gray-50 md:my-0 md:mb-4 hover:text-neutral-800 md:hover:text-turquoise-400"
      @click="saveInCookie(church.churchId)"
    >
      <!-- Church Image -->
      <div class="h-16 w-16 flex-shrink-0">
        <img
          :src="getOptimizedImage(church.churchImage)"
          :alt="`Kirchenbild von ${church.title}`"
          :class="{
      'object-cover': church.churchImage,
      'object-none': !church.churchImage,
    }"
        />
      </div>


      <!-- Church Name + PLZ + Town -->
      <div class="flex flex-col tracking-normal">
        <span class="font-[demos-next-condensed] font-medium text-sm">{{ church.title }}</span>
        <span class="font-[BlissPro] font-medium text-neutral text-xs ">
          {{ church.plz }} {{ church.town }}
        </span>
      </div>

      <!-- Chevron Icon -->
      <div
        class="ml-auto -translate-x-2 pr-4 opacity-0 transition-transform duration-300 group-hover:translate-x-0 group-hover:opacity-100 group-focus:translate-x-0 group-focus:opacity-100 hidden md:block text-turquoise-400"
      >
        <ChevronIcon />
      </div>
    </a>
  </div>

  <!-- 0 Results -->
  <div v-else>
    <span class="text-neutral-800">
      <template v-if="searchTerm && !fromDate && !toDate">
        Wir haben leider keine Treffer für
        <span class="text-turquoise-400">"{{ searchTerm }}"</span> gefunden. Tipp: Ändern Sie Ihren
        Suchbegriff.
      </template>

      <template v-else-if="!searchTerm && (fromDate || toDate)">
        Leider keine Gottesdienste
        <template v-if="fromDate && toDate"
          >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
        >
        <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
        <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template> gefunden. Tipp:
        Erweitern Sie Ihren Suchzeitraum.
      </template>

      <template v-else-if="searchTerm && (fromDate || toDate)">
        Leider keine Treffer für <span class="text-turquoise-400">"{{ searchTerm }}"</span>. Wir haben
        leider keine entsprechenden Gottesdienste
        <template v-if="fromDate && toDate"
          >im Zeitraum {{ formatDate(fromDate) }} – {{ formatDate(toDate) }}</template
        >
        <template v-else-if="fromDate">ab {{ formatDate(fromDate) }}</template>
        <template v-else-if="toDate">bis {{ formatDate(toDate) }}</template> gefunden. Tipp: Ändern
        Sie Ihren Suchzeitraum oder -Begriff.
      </template>
    </span>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted, onUnmounted } from 'vue';
import ChevronIcon from './__chevronIcon.vue';

const props = defineProps({
  filteredChurches: Array,
  searchTerm: String,
  fromDate: String,
  toDate: String,
  isLoading: Boolean,
  isFeaturedSearch: Boolean,
});

const loadingStep = ref(0);
let loadingInterval = null;

const loadingDots = computed(() => {
  const dots = ['', '.', '..', '...'];
  return dots[loadingStep.value % dots.length];
});

onMounted(() => {
  loadingInterval = setInterval(() => {
    loadingStep.value++;
  }, 500);
});

onUnmounted(() => {
  clearInterval(loadingInterval);
});

const formatDate = (dateString) => {
  if (!dateString) return '';
  const [year, month, day] = dateString.split('-');
  return `${day}.${month}.${year}`;
};

const getOptimizedImage = (imagePath) => {
  const imgixBaseUrl = 'https://bistumpassau.imgix.net/images/';
  const fallbackBaseUrl = 'https://bistumpassau.imgix.net/graphics/fallback-church.png';

  const params = '?auto=format&crop=focalpoint&fit=crop&fp-x=0.5&fp-y=0.5&h=128&w=128&q=95';

  return imagePath
    ? `${imgixBaseUrl}${encodeURIComponent(imagePath)}${params}`
    : `${fallbackBaseUrl}${params}`;
};

/** Add the selected church ID at the top of "recentChurches" cookie, keeping max 8 entries for 14 days. */
const saveInCookie = (churchId) => {
  const cookieName = 'recentChurches';
  const maxEntries = 8;

  let recentChurches = [];

  const storedData = document.cookie.split('; ').find((row) => row.startsWith(cookieName + '='));
  if (storedData) {
    try {
      recentChurches = JSON.parse(decodeURIComponent(storedData.split('=')[1]));
    } catch (error) {
      console.error('Fehler beim Parsen des Cookies', error);
    }
  }

  recentChurches = [churchId, ...recentChurches.filter((id) => id !== churchId)].slice(
    0,
    maxEntries,
  );

  document.cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify(recentChurches),
  )}; path=/; max-age=1209600`;
};
</script>
