<script setup>
import { defineProps, defineEmits, watch, ref, onMounted } from 'vue';

const props = defineProps({
  fromDate: String,
  toDate: String,
});

const emit = defineEmits(['update:fromDate', 'update:toDate', 'filterApplied']);

const localFromDate = ref(props.fromDate || '');
const localToDate = ref(props.toDate || '');
const today = ref(formatDate(new Date()));

const fromDateInput = ref(null);
const toDateInput = ref(null);

const activeFilter = ref('');

const isMobile = ref(false);

onMounted(() => {
  isMobile.value = window.innerWidth < 800;
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 800;
  });
});

watch(localFromDate, (newVal) => {
  emit('update:fromDate', newVal);
  if (isMobile.value) applyFilter();
});

watch(localToDate, (newVal) => {
  emit('update:toDate', newVal);
  if (isMobile.value) applyFilter();
});

watch(localFromDate, (newVal) => emit('update:fromDate', newVal));
watch(localToDate, (newVal) => emit('update:toDate', newVal));

function formatDate(date) {
  return date.toISOString().split('T')[0];
}

const openDatepicker = (event, refName) => {
  event.preventDefault();
  if (refName === 'fromDateInput' && fromDateInput.value) {
    fromDateInput.value.showPicker();
  } else if (refName === 'toDateInput' && toDateInput.value) {
    toDateInput.value.showPicker();
  }
};

const resetActiveFilter = () => {
  activeFilter.value = ''; // Clear active state when input is clicked
};

const setDateRange = (type) => {
  const today = new Date();
  let startDate = '';
  let endDate = '';

  switch (type) {
    case 'today':
      startDate = formatDate(today);
      endDate = formatDate(today);
      break;
    case 'tomorrow':
      startDate = new Date(today);
      startDate.setDate(today.getDate() + 1);
      endDate = formatDate(startDate);
      startDate = formatDate(startDate);
      break;
    case 'thisWeek':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - today.getDay() + 1);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      break;
    case 'nextWeek':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - today.getDay() + 8);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      break;
    case 'default':
      startDate = null;
      endDate = null;
      break;
  }

  localFromDate.value = startDate;
  localToDate.value = endDate;
  activeFilter.value = type;

  emit('filterApplied', { fromDate: startDate, toDate: endDate });
};

const applyFilter = () => {
  emit('filterApplied', { fromDate: localFromDate.value, toDate: localToDate.value });
};
</script>

<template>
  <div class="hidden md:block">
    <span class="mb-7 font-[demos-next-condensed] text-lg">Gottesdienste filtern</span>
  </div>

  <div class="flex w-full gap-4 md:flex-col">
    <div class="w-full">
      <label for="fromDate">Von</label>
      <input
        id="fromDate"
        ref="fromDateInput"
        v-model="localFromDate"
        :min="today"
        type="date"
        :class="[localFromDate ? 'text-gray-900' : 'text-gray-400', 'font-normal pr-4']"
        style="min-width: -webkit-fill-available"
        @click="
          openDatepicker($event, 'fromDateInput');
          resetActiveFilter();
        "
      />
    </div>
    <div class="w-full">
      <label for="toDate">Bis</label>
      <input
        id="toDate"
        ref="toDateInput"
        v-model="localToDate"
        :min="localFromDate || today"
        type="date"
        :class="[localToDate ? 'text-gray-900' : 'text-gray-400', 'font-normal pr-4']"
        style="min-width: -webkit-fill-available"
        @click="
          openDatepicker($event, 'toDateInput');
          resetActiveFilter();
        "
      />
    </div>

    <div class="hidden w-full md:block">
      <button
        class="w-full rounded border-2 border-[#5BC3BE] bg-[#ffffff] px-4 py-3 font-[BlissPro] font-normal text-[#5BC3BE] transition-colors duration-150 hover:bg-[#5BC3BE] hover:text-[#ffffff]"
        @click="applyFilter"
      >
        Gottesdienste anzeigen
      </button>
    </div>
  </div>

  <ul class="mt-0 hidden space-y-1 font-[demos-next-condensed] md:block">
    <li>
      <button
        class="hover:text-[#5bc3be]"
        :class="{ 'text-[#5bc3be] underline underline-offset-[3px]': activeFilter === 'default' }"
        @click="setDateRange('default')"
      >
        Alle Daten
      </button>
    </li>
    <li>
      <button
        class="hover:text-[#5bc3be]"
        :class="{ 'text-[#5bc3be] underline underline-offset-[3px]': activeFilter === 'today' }"
        @click="setDateRange('today')"
      >
        Heute
      </button>
    </li>
    <li>
      <button
        class="hover:text-[#5bc3be]"
        :class="{ 'text-[#5bc3be] underline underline-offset-[3px]': activeFilter === 'tomorrow' }"
        @click="setDateRange('tomorrow')"
      >
        Morgen
      </button>
    </li>
    <li>
      <button
        class="hover:text-[#5bc3be]"
        :class="{ 'text-[#5bc3be] underline underline-offset-[3px]': activeFilter === 'thisWeek' }"
        @click="setDateRange('thisWeek')"
      >
        Diese Woche
      </button>
    </li>
    <li>
      <button
        class="hover:text-[#5bc3be]"
        :class="{ 'text-[#5bc3be] underline underline-offset-[3px]': activeFilter === 'nextWeek' }"
        @click="setDateRange('nextWeek')"
      >
        Nächste Woche
      </button>
    </li>
  </ul>

  <div aria-live="polite" class="sr-only">
    {{ localFromDate ? `Von: ${localFromDate}` : '' }}
    {{ localToDate ? `Bis: ${localToDate}` : '' }}
  </div>
</template>
