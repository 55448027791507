<!-- Use cloned Meta-Navi -->
<template>
  <div>
    <nav class="!pt-0" ref="metaNavContainer"></nav>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  name: 'MetaNav',
  setup() {
    const metaNavContainer = ref(null); // Reference for <nav>

    onMounted(() => {
      const metaNavigation = document.querySelector('.m-navMain__headList');

      if (metaNavigation && metaNavContainer.value) {
        const clonedMetaNav = metaNavigation.cloneNode(true);
        metaNavContainer.value.appendChild(clonedMetaNav);
      }
    });

    return { metaNavContainer };
  },
};
</script>
