<script setup>
import { defineProps, ref, computed, onMounted, onUnmounted } from 'vue';
import DateFilter from './__dateFilter.vue';
import SearchResults from './__searchResults.vue';
import SearchIcon from './__searchIcon.vue';
import CloseIcon from './__closeIcon.vue';
import axios from 'axios';
import {enableBodyScroll} from "body-scroll-lock";

const searchTerm = ref('');
const hasTyped = ref(false);
const props = defineProps({
  allChurches: Object,
  churchList: Object,
  isFeaturedSearch: Boolean,
});

const fromDate = ref('');
const toDate = ref('');

// To store the filtered churches
const churchList = ref([...props.churchList]);
const filterApplied = ref(false);

const handleInput = () => {
  hasTyped.value = searchTerm.value.trim().length > 0;
};

const handleClose = () => {
  if (hasTyped.value) {
    searchTerm.value = '';
    hasTyped.value = false;
  } else {
    closeModal();
    updateUrl();
  }
};

const closeModal = () => {
  const modal = document.getElementById('searchModal');
  if (modal) {
    modal.close();
    enableBodyScroll(modal);
  }
};

const handleEscape = (event) => {
  if (event.key === 'Escape') {
    closeModal();
    updateUrl();
  }
};

const updateUrl = () => {
  const url = new URL(window.location.href);
  if (url.searchParams.has('gottesdienstsuche')) {
    url.searchParams.delete('gottesdienstsuche');
    window.history.pushState({}, '', url.toString());
  }
}

const isLoading = ref(false);

const fetchChurchesByDate = async (from, to) => {
  try {
    let loadingTimeout = setTimeout(() => {
      isLoading.value = true;
    }, 100);

    const response = await axios.get(`/intentio/active-churches?from=${from}&to=${to}`);

    clearTimeout(loadingTimeout);
    isLoading.value = false;

    churchList.value = response.data.filter((church) => church.servicesInfo?.amount > 0);

  } catch (error) {
    isLoading.value = false;
  }
};

const handleFilterApplied = ({ fromDate, toDate }) => {
  filterApplied.value = true;
  fetchChurchesByDate(fromDate || '', toDate || '');
};

onMounted(() => {
  window.addEventListener('keydown', handleEscape);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscape);
});

const filteredChurches = computed(() => {
  const searchQuery = searchTerm.value.trim().toLowerCase();

  // Get the correct base list (filtered or default)
  const availableChurches = filterApplied.value ? churchList.value : props.churchList;

  // Only use churches that have services
  const churchesWithServices = (churches) =>
    Array.isArray(churches) ? churches.filter((church) => church.servicesInfo?.amount > 0) : [];

  // If no search term, return the filtered churches list
  if (!searchQuery) {
    return churchesWithServices(availableChurches);
  }

  // If no filter is applied, search in all churches with services
  const searchableChurches = filterApplied.value
    ? availableChurches
    : churchesWithServices(props.allChurches.list || []);

  // Search only in churches that have services
  return searchableChurches.filter((church) => {
    const title = church.title?.toLowerCase() || '';
    const town = church.town?.toLowerCase() || '';
    const plz = church.plz?.toString() || '';

    return title.includes(searchQuery) || town.includes(searchQuery) || plz.includes(searchQuery);
  });
});
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="relative flex w-full border-t border-neutral-200 md:h-auto md:border-t-0">
      <div>
        <SearchIcon class="h-full w-[45px] md:w-full" />
      </div>

      <input
        id="searchbarInput"
        v-model="searchTerm"
        type="text"
        placeholder="Kirche oder Ort eingeben"
        autocomplete="off"
        aria-autocomplete="none"
        spellcheck="false"
        class="ml-2 h-[74px] w-full p-2 font-[BlissPro] font-light text-neutral-800 caret-turquoise-400 text-lg md:text-2xl placeholder:text-neutral-500"
        @input="handleInput"
      />

      <button id="close" class="m-auto p-2" aria-label="Suche schließen" @click="handleClose">
        <CloseIcon class="h-full cursor-pointer text-turquoise-400" />
      </button>
    </div>
    <div
      class="relative lg:-ml-56 md:-ml-36 hidden h-px md:w-[calc(100%+9rem)] lg:w-[calc(100%+14rem)] bg-neutral-200 md:block"
    ></div>
  </div>

  <div class="md:absolute md:w-full md:left-0 md:pl-10 md:pr-12 lg:relative lg:p-0">
    <div
      class="flex flex-col border-t border-neutral-200 pt-4 md:flex-row md:gap-12 md:border-none md:pt-8"
    >
      <!-- Results -->
      <div class="order-1 mt-6 w-full md:order-none md:mt-0">
        <SearchResults
          :filtered-churches="filteredChurches"
          :search-term="searchTerm"
          :from-date="fromDate"
          :to-date="toDate"
          :is-loading="isLoading"
          :isFeaturedSearch="isFeaturedSearch"
        />
      </div>
      <!-- Date Filter Box -->
      <div
        class="order-0 flex w-auto flex-shrink-0 flex-col items-start gap-7 md:sticky md:top-0 md:order-none md:ml-auto md:mt-11 md:self-start md:bg-neutral-50 md:px-8 md:pt-7 md:pb-10"
      >
        <DateFilter
          v-model:fromDate="fromDate"
          v-model:toDate="toDate"
          @filter-applied="handleFilterApplied"
        />
      </div>
    </div>
  </div>
</template>
