<!-- eslint-disable -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <g clip-path="url(#clip0_5197_3428)">
      <path d="M13.3333 21.833L18.6666 16.4997L13.3333 11.1663" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
    </g>
  </svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'CloseIcon',
};
</script>
